import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NFTGallery from './components/NFTGallery';
import NFTDetail from './NFTDetail';

function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<NFTGallery />} />
        <Route path="/nft/:id" element={<NFTDetail />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
