// NFTGallery.js
import React, { useState, useEffect, useMemo } from 'react';
import './NFTGallery.css';
import './NFTDetailModal.css';
import NFTDetailModal from './NFTDetailModal';
import Papa from 'papaparse';

const NFTGallery = () => {
  const [nfts, setNfts] = useState([]);
  const [displayCount] = useState(6900);
  const [filters, setFilters] = useState({});
  const [selectedNFT, setSelectedNFT] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const [isOneOfOneFilterActive, setIsOneOfOneFilterActive] = useState(false);
  const [sortOption, setSortOption] = useState('MONO #');
  const [listedPrices, setListedPrices] = useState({});


  const imageUrl = "https://storage.googleapis.com/cryptomonos/monos/Logo_Text.png";

  useEffect(() => {
    // Fetch listed prices from your server-side script or third-party service
    const fetchListedPrices = async () => {
      try {
        const response = await fetch('https://docs.google.com/spreadsheets/d/e/2PACX-1vSnp8KTUaeiWi3Wi9d6hpLtqmxqs-yDBLonBsrd1JWdVRIfB_OAGgWCPdHrC15JpRjPmQ2n5IKZY-9o/pub?gid=0&single=true&output=csv');
        const text = await response.text(); // Get the CSV data as text

        // Parse the CSV data
        Papa.parse(text, {
          header: true, // Assumes the first row of the CSV are headers
          complete: (results) => {
            const pricesMapping = results.data.reduce((acc, item) => {
              if (item.id && item.price) {
                acc[item.id.trim()] = item.price.trim();
              }
              return acc;
            }, {});

            setListedPrices(pricesMapping);
          }
        });
      } catch (error) {
        console.error('Error fetching and parsing listed prices:', error);
      }
    };

    fetchListedPrices();
  }, []); // Empty dependency array to fetch only on component mount

  useEffect(() => {
    fetch('/metadata.json')
      .then((response) => response.json())
      .then((data) => {
        // The NFTs are nested under the "nfts" key in your JSON
        const sortedData = data.nfts.sort((a, b) => parseInt(a.id) - parseInt(b.id));
        setNfts(sortedData);
      });
  }, []);

  const openModal = (nft) => {
    setSelectedNFT(nft);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Function to handle filter change
  const handleFilterChange = (traitType, value) => {
    setFilters((prevFilters) => {
      // Clone the previous filters object
      const newFilters = { ...prevFilters };
  
      if (value === 'All') {
        // If 'All' is selected, remove the filter for this trait type
        delete newFilters[traitType];
      } else {
        // Otherwise, set or update the filter for this trait type
        newFilters[traitType] = value;
      }
  
      return newFilters;
    });
  };

  const filteredNFTs = nfts.filter((nft) => {
    const isOneOfOne = isOneOfOneFilterActive 
      ? nft.attributes.some(trait => trait.trait_type === "1/1") 
      : true;
  
    // Apply other filters
    const matchesOtherFilters = Object.entries(filters).every(([key, value]) =>
      // If a filter is set to "All", consider it as always matching, otherwise, check the trait value
      value === 'All' || nft.attributes.some(trait => trait.trait_type === key && trait.value === value)
    );
  
    return isOneOfOne && matchesOtherFilters;
  }).slice(0, displayCount);

  // Fetch unique trait types for filters
  const traitTypes = [...new Set(nfts.flatMap((nft) => nft.attributes.map((trait) => trait.trait_type)))];

  const toggleSidebar = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };

  const resetFilters = () => {
    const resetState = {};
    // Assuming 'traitTypes' is an array of your filter keys
    traitTypes.forEach(traitType => {
      resetState[traitType] = 'All';
    });
    setFilters({});
  };

  const traitCounts = {
    "Aquamarine": 6879,
    "Brown": 659,
    "Black": 634,
    "Dark Brown": 625,
    "Golden Brown": 573,
    "Cream": 533,
    "Tan": 478,
    "Pink": 442,
    "Gray": 420,
    "Blue": 401,
    "Red Fur": 360,
    "White Fur": 314,
    "Cheetah": 252,
    "Death Bot": 195,
    "Zombie": 192,
    "Robot": 187,
    "DMT": 180,
    "Noise": 172,
    "Trippy": 55,
    "Solid Gold": 6,
    "Regular": 418,
    "VR": 339,
    "Nerd": 338,
    "Big": 305,
    "Classic": 293,
    "Bifocals": 284,
    "3D": 277,
    "Chad": 244,
    "Eye Mask": 235,
    "Horned Rim": 213,
    "Eye Patch": 205,
    "Cobain": 170,
    "Nice": 150,
    "White": 144,
    "Luxe": 140,
    "Red": 139,
    "Nounish": 41,
    "Rocketman": 28,
    "Mohawk Black": 267,
    "Mohawk": 219,
    "Mohawk Thin": 201,
    "Messy": 197,
    "Wild": 195,
    "Short Dark": 184,
    "Purple": 180,
    "Crazy": 179,
    "Peak Spike": 177,
    "Short Blonde": 142,
    "Flat Top": 97,
    "Afro Black": 96,
    "Mullet Dark": 95,
    "Spiky": 86,
    "Mullet Blonde": 79,
    "Wild Blonde": 79,
    "Loco Blonde": 77,
    "Afro Blonde": 76,
    "Rainbow Hair": 73,
    "Bald": 72,
    "Straight Dark": 71,
    "Wild White": 63,
    "Pigtails": 52,
    "Clown": 44,
    "Straight Blonde": 38,
    "Orange Side": 35,
    "Gold Tooth": 962,
    "Smile": 459,
    "Bleh": 422,
    "Frown": 177,
    "Lipstick": 146,
    "Rainbow": 134,
    "Buck Teeth": 47,
    "Mono": 6699,
    "Skelly": 61,
    "Frog": 58,
    "Sloth": 42,
    "Lemur": 18,
    "Moose": 16,
    "Alien": 6,
    "Bloodshot": 254,
    "Gremplin": 213,
    "Clown Green": 211,
    "Clown Blue": 205,
    "Crosseyed": 86,
    "Bandana": 208,
    "Farmer": 207,
    "Fez": 202,
    "Cap Forward": 198,
    "Fedora": 187,
    "Knitted": 186,
    "Pirate": 157,
    "Bowler": 154,
    "Cap Backward": 147,
    "Headband": 140,
    "Viking": 137,
    "Do-rag": 135,
    "Feather": 130,
    "Cap": 124,
    "Captain": 102,
    "Mushroom": 99,
    "Cowboy": 93,
    "Halo": 84,
    "Brain": 83,
    "Unicorn": 81,
    "Police": 80,
    "Top Hat": 76,
    "Banana Peel": 74,
    "Rock": 69,
    "Illuminati": 55,
    "Wolf": 42,
    "Tassle": 40,
    "Hoodie": 30,
    "Tiara": 26,
    "Beanie": 25,
    "Crown": 17,
    "Astronaut": 11,
    "Goat": 370,
    "Normal Beard": 311,
    "Mustache": 287,
    "Chinstrap": 276,
    "Handlebars": 237,
    "Normal Beard Black": 145,
    "Luxurious Beard": 134,
    "Big Beard": 57,
    "Earring": 713,
    "Nosering": 661,
    "Silver Chain": 620,
    "Gold Chain": 77,
    "Vape": 331,
    "Blunt": 303,
    "Pipe": 94,
    "Ant": 1,
    "Bump": 1,
    "DC": 1,
    "dob": 1,
    "Frank": 1,
    "gL!TcH3d": 1,
    "Hawk": 1,
    "K.A.": 1,
    "Kaleo": 1,
    "King": 1,
    "Mooncat": 1,
    "Nick": 1,
    "Pio": 1,
    "Seiyan": 1,
    "Seneca": 1,
    "Shane": 1,
    "Shooter": 1,
    "Spooky": 1,
    "Thread": 1,
    "Toad": 1,
    "Zen": 1,
    "1": 21,
    "2": 5,
    "3": 132,
    "4": 370,
    "5": 2210,
    "6": 3042,
    "7": 530,
    "8": 590
  };

  const order = ["Type", "Eyes", "Facial Hair", "Fur", "Glasses", "Hair", "Hat", "Jewelry", "Mouth", "Smoke", "Trait Count"]; // Example order, adjust based on your actual traits
  const filteredAndOrderedTraitTypes = order.filter(traitType => traitType !== "Background");

  const sortedAndFilteredNFTs = useMemo(() => {
    const filtered = nfts.filter((nft) => {
      const isOneOfOne = isOneOfOneFilterActive 
      ? nft.attributes.some(trait => trait.trait_type === "1/1") 
      : true;
  
    // Apply other filters
    const matchesOtherFilters = Object.entries(filters).every(([key, value]) =>
      // If a filter is set to "All", consider it as always matching, otherwise, check the trait value
      value === 'All' || nft.attributes.some(trait => trait.trait_type === key && trait.value === value)
    );
  
    return isOneOfOne && matchesOtherFilters;
    }).slice(0, displayCount);
  
    return filtered.sort((a, b) => {
      if (sortOption === 'Listed') {
        // Handle sorting by listed price
        const priceA = listedPrices[a.id] ? parseFloat(listedPrices[a.id]) : Number.MAX_VALUE;
        const priceB = listedPrices[b.id] ? parseFloat(listedPrices[b.id]) : Number.MAX_VALUE;
        return priceA - priceB;
      } if (sortOption === 'Rank') {
        return parseInt(a.rank, 10) - parseInt(b.rank, 10);
      } else { // Default to sorting by MONO #
        return a.id - b.id;
      }
    });
  }, [nfts, sortOption, filters, listedPrices, isOneOfOneFilterActive, displayCount]); // Include other dependencies as needed

  return (
  <div style={{ backgroundColor: '#000' }}>
    <div className="sort-bar" style={{padding: '2px', display: 'flex', width: '100%'}}>
      <div className="logo-container">
        <a href="https://www.cryptomonos.com/" target="_blank" rel="noopener noreferrer">
          <img src={imageUrl} alt="Logo" className="img-fluid" />
        </a>
      </div>
      <div className='sort-and-count-container'>
        <select id="sort-select" value={sortOption} onChange={e => setSortOption(e.target.value)} style={{ height: '30px', margin: '10px', fontFamily: 'Trebuchet MS'}}>
          <option value="MONO #">MONO #</option>
          <option value="Rank">Rank</option>
          <option value="Listed">Listed</option>
        </select>
        <span style={{ color: 'whitesmoke', paddingTop: '14px', paddingRight: '4px' }}>{sortedAndFilteredNFTs.length} NFTs</span>
      </div>
    </div>
    <div className="container">
      <aside className="sidebar" style={{ width: isSidebarExpanded ? '145px' : '40px' }}>
        <button onClick={toggleSidebar} className="toggle-sidebar-btn">
          {isSidebarExpanded ? 'Hide' : '>'}
        </button>
        {isSidebarExpanded && (
          <>
            {filteredAndOrderedTraitTypes.map((traitType) => (
              <div key={traitType} className="filter-group">
                <label htmlFor={traitType}>{traitType}</label>
                <select
                  value={filters[traitType] || ''}
                  id={traitType}
                  onChange={(e) => handleFilterChange(traitType, e.target.value)}
                >
                  <option value="">{`Select ${traitType}`}</option>
                  {
                    [...new Set(nfts.flatMap((nft) => 
                      nft.attributes
                        .filter((trait) => 
                          trait.trait_type === traitType && 
                          !(traitType === 'Eyes' && trait.value === 'Regular') // Exclude 'Normal' for 'Eyes'
                        )
                        .map((trait) => trait.value)
                    ))]
                    .map(value => (
                    { value, count: traitCounts[value] || 0 })) // Map to objects with value and count
                    .sort((a, b) => b.count - a.count) // Sort by count descending
                    .map(({ value, count }) => (
                      <option key={value} value={value}>{`${value} (${count})`}</option>                      
                    ))}
                </select>
              </div>
            ))}
            <button onClick={() => setIsOneOfOneFilterActive(!isOneOfOneFilterActive)} className="ones-btn">
              {isOneOfOneFilterActive ? "Show All NFTs" : "Show 1/1 NFTs"}
            </button>
            <div className="sidebar-footer">
              <button onClick={resetFilters} className="reset-filters-btn">Reset Filters</button>
            </div>
          </>
        )}
      </aside>
      <main className="gallery">
        {sortedAndFilteredNFTs.map((nft) => (
          <div key={nft.id} className="card" onClick={() => openModal(nft)}>
            <div className="card-image">
              <img src={nft.image_url} alt={nft.name} />
            </div>
            <div className="card-body">
              <h5 className="card-title">{nft.name}</h5>
              {/* Render additional NFT details here */}
            </div>
            <div>
              {listedPrices[nft.id] && (
                <div className="nft-listed-pill" style={{ backgroundColor: '#ED5D42', color: 'whitesmoke' }}>
                  Listed: {listedPrices[nft.id]}
                </div>
              )}
            </div>
            <div className="nft-rank">
              Rank: {nft.rank}
            </div>
          </div>
        ))}
        {isModalOpen && <NFTDetailModal nft={selectedNFT} listedPrice={listedPrices[selectedNFT.id]} onClose={closeModal} />}
        <div className="load-btn-div">
          {filteredNFTs.slice(0, displayCount).map((nft) => (
            <div key={nft.id}>{/* NFT card content */}</div>
          ))}
        </div>
      </main>
    </div>
  </div>
  );
};

export default NFTGallery;