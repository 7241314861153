import React from 'react';
import './NFTDetailModal.css';

const NFTDetailModal = ({ nft, listedPrice, onClose }) => {
  if (!nft) return null;

  // Function to stop event propagation for clicks inside the modal
  const handleModalContentClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="modal" onClick={onClose}>
      <div className="modal-content" onClick={handleModalContentClick}>
        <div className="container-fluid">
            <div class="row">
                <span className="close" onClick={onClose}>&times;</span><h3>{nft.name}</h3>
                <div className="image-section">
                    <img src={nft.image_url} alt={nft.name} className="nft-image" />
                </div>
                <div className="card-rank">
                  Rank: {nft.rank}
                </div>
                <div>
                {listedPrice && (
                  <a href={`https://pallet.exchange/collection/cryptomonos/${nft.id}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <div className="nft-listed-pill-modal" style={{ backgroundColor: '#ED5D42', color: 'whitesmoke' }}>
                      Buy on Pallet | Listed: {listedPrice} SEI
                    </div>
                  </a>
                )}
                </div>
            </div>
            <div className="details-row">
              <div className="details-section">
                <div className="traits-section">
                  {nft.attributes.map((trait, index) => (
                    <div key={index} className="trait-pill">
                      <div className="details-trait-type" style={{color: '#747476'}}>
                        {trait.trait_type}
                      </div>
                      <div className="details-trait-value" style={{display: 'flex', justifyContent: 'space-between'}}>  
                        <div>
                          {trait.value}
                        </div>
                        <div>
                          {trait.rarity}%
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default NFTDetailModal;