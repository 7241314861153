import React from 'react';

const NFTDetail = ({ nft }) => {
  if (!nft) return null; // Return null or a loading spinner if the NFT data is not yet available

  return (
    <div className="nft-detail">
      <img src={nft.image} alt={nft.name} />
      <h2>{nft.name}</h2>
      <ul>
        {nft.attributes.map((trait, index) => (
          <li key={index}>{trait.trait_type}: {trait.value}</li>
        ))}
      </ul>
      <p>Rarity: {nft.rarity}</p>
    </div>
  );
};

export default NFTDetail;